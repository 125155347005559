import { default as accountUfcFuOdy62Meta } from "/home/michael/Desktop/michael-strain/pages/account.vue?macro=true";
import { default as indexD2dej3epJBMeta } from "/home/michael/Desktop/michael-strain/pages/admin/auctions/index.vue?macro=true";
import { default as indexRmIG7EIFCqMeta } from "/home/michael/Desktop/michael-strain/pages/admin/index.vue?macro=true";
import { default as ashYSpceG9LQsMeta } from "/home/michael/Desktop/michael-strain/pages/ash.vue?macro=true";
import { default as crudUCEJEWZJScMeta } from "/home/michael/Desktop/michael-strain/pages/blog/crud.vue?macro=true";
import { default as exampleZPhLz54UlRMeta } from "/home/michael/Desktop/michael-strain/pages/blog/example.vue?macro=true";
import { default as index3Lx7xfROUyMeta } from "/home/michael/Desktop/michael-strain/pages/blog/index.vue?macro=true";
import { default as laVistanCTrwSyTD5Meta } from "/home/michael/Desktop/michael-strain/pages/blog/laVista.vue?macro=true";
import { default as laVista2U4GKqbUdpAMeta } from "/home/michael/Desktop/michael-strain/pages/blog/laVista2.vue?macro=true";
import { default as location15O3CVwSSOMeta } from "/home/michael/Desktop/michael-strain/pages/blog/location.vue?macro=true";
import { default as personal_45timeline5Aipkyc9QWMeta } from "/home/michael/Desktop/michael-strain/pages/blog/personal-timeline.vue?macro=true";
import { default as slugmaybevH84UuutwaMeta } from "/home/michael/Desktop/michael-strain/pages/blog/slugmaybe.vue?macro=true";
import { default as contactlsRwYzvf4oMeta } from "/home/michael/Desktop/michael-strain/pages/contact.vue?macro=true";
import { default as donatelS3el9txF6Meta } from "/home/michael/Desktop/michael-strain/pages/donate.vue?macro=true";
import { default as indexBZG41AccHaMeta } from "/home/michael/Desktop/michael-strain/pages/goals/index.vue?macro=true";
import { default as index3iBi4D6y46Meta } from "/home/michael/Desktop/michael-strain/pages/index.vue?macro=true";
import { default as investpkQB89Qlo7Meta } from "/home/michael/Desktop/michael-strain/pages/invest.vue?macro=true";
import { default as applicationhQiv0UeFQXMeta } from "/home/michael/Desktop/michael-strain/pages/investors/application.vue?macro=true";
import { default as indexDc3w7VQNjMMeta } from "/home/michael/Desktop/michael-strain/pages/investors/index.vue?macro=true";
import { default as portfolioNnoEFJrLC2Meta } from "/home/michael/Desktop/michael-strain/pages/investors/portfolio.vue?macro=true";
import { default as disclosuresgw5mJLBi2rMeta } from "/home/michael/Desktop/michael-strain/pages/legal/disclosures.vue?macro=true";
import { default as faqsdVZxqB5qOVMeta } from "/home/michael/Desktop/michael-strain/pages/legal/faqs.vue?macro=true";
import { default as indexK75I3ABR10Meta } from "/home/michael/Desktop/michael-strain/pages/legal/index.vue?macro=true";
import { default as investor_45terms606ugDmIi1Meta } from "/home/michael/Desktop/michael-strain/pages/legal/investor-terms.vue?macro=true";
import { default as privacyPolicyE5BmpAasvLMeta } from "/home/michael/Desktop/michael-strain/pages/legal/privacyPolicy.vue?macro=true";
import { default as seller_45termsu9DQ1QrpUyMeta } from "/home/michael/Desktop/michael-strain/pages/legal/seller-terms.vue?macro=true";
import { default as termsAndConditionspYQeP4dBIVMeta } from "/home/michael/Desktop/michael-strain/pages/legal/termsAndConditions.vue?macro=true";
import { default as login3l9WrS1eC6Meta } from "/home/michael/Desktop/michael-strain/pages/login.vue?macro=true";
import { default as logoutoJ4wLKVXDCMeta } from "/home/michael/Desktop/michael-strain/pages/logout.vue?macro=true";
import { default as offerxRt5TSANwjMeta } from "/home/michael/Desktop/michael-strain/pages/offer.vue?macro=true";
import { default as oldindexHHFGOTePwQMeta } from "/home/michael/Desktop/michael-strain/pages/oldindex.vue?macro=true";
import { default as oldlanding1cpjA1geoJMeta } from "/home/michael/Desktop/michael-strain/pages/oldlanding.vue?macro=true";
import { default as project_45proposalKF0xAuKcl6Meta } from "/home/michael/Desktop/michael-strain/pages/project-proposal.vue?macro=true";
import { default as indexPnzFpeMg80Meta } from "/home/michael/Desktop/michael-strain/pages/projects/cannabis/index.vue?macro=true";
import { default as indexj8w1SAUAdYMeta } from "/home/michael/Desktop/michael-strain/pages/projects/farm/index.vue?macro=true";
import { default as indexISWD7O9UWBMeta } from "/home/michael/Desktop/michael-strain/pages/projects/harvest-valley/index.vue?macro=true";
import { default as indexIOsXnrMphhMeta } from "/home/michael/Desktop/michael-strain/pages/projects/index.vue?macro=true";
import { default as activetestCruoNDHc23Meta } from "/home/michael/Desktop/michael-strain/pages/reference/activetest.vue?macro=true";
import { default as pretty_45test2alucT0pp8Meta } from "/home/michael/Desktop/michael-strain/pages/reference/pretty-test.vue?macro=true";
import { default as testDkztoefIDmMeta } from "/home/michael/Desktop/michael-strain/pages/reference/test.vue?macro=true";
import { default as schedule_45interviewahu5kcfBXZMeta } from "/home/michael/Desktop/michael-strain/pages/schedule-interview.vue?macro=true";
import { default as schedulerlXegQlJ9VCMeta } from "/home/michael/Desktop/michael-strain/pages/scheduler.vue?macro=true";
import { default as seller_45application9Khf6070S6Meta } from "/home/michael/Desktop/michael-strain/pages/seller-application.vue?macro=true";
import { default as accountingltzhiAOj8AMeta } from "/home/michael/Desktop/michael-strain/pages/services/business/accounting.vue?macro=true";
import { default as consultinglV9ntjHRaPMeta } from "/home/michael/Desktop/michael-strain/pages/services/business/consulting.vue?macro=true";
import { default as digitalMarketing2TuLuCHUrXMeta } from "/home/michael/Desktop/michael-strain/pages/services/business/digitalMarketing.vue?macro=true";
import { default as fulfillmentlwHT4FbsMUMeta } from "/home/michael/Desktop/michael-strain/pages/services/business/fulfillment.vue?macro=true";
import { default as indexTmemEQIje2Meta } from "/home/michael/Desktop/michael-strain/pages/services/business/index.vue?macro=true";
import { default as legalzqPSv8mu0BMeta } from "/home/michael/Desktop/michael-strain/pages/services/business/legal.vue?macro=true";
import { default as indexO45USF7RV7Meta } from "/home/michael/Desktop/michael-strain/pages/services/callCenter/index.vue?macro=true";
import { default as customProductsWlxN9tMq2XMeta } from "/home/michael/Desktop/michael-strain/pages/services/creative/art/customProducts.vue?macro=true";
import { default as digitalPrintscQJWl5yK0XMeta } from "/home/michael/Desktop/michael-strain/pages/services/creative/art/digitalPrints.vue?macro=true";
import { default as graphicDesigncj73jgXCGiMeta } from "/home/michael/Desktop/michael-strain/pages/services/creative/art/graphicDesign.vue?macro=true";
import { default as indexLnF19B3tt3Meta } from "/home/michael/Desktop/michael-strain/pages/services/creative/art/index.vue?macro=true";
import { default as physicalPaintingsJFqPjDAP7cMeta } from "/home/michael/Desktop/michael-strain/pages/services/creative/art/physicalPaintings.vue?macro=true";
import { default as authorshipvO9ldzmiEFMeta } from "/home/michael/Desktop/michael-strain/pages/services/creative/authorship.vue?macro=true";
import { default as indexW8TjXC1g9xMeta } from "/home/michael/Desktop/michael-strain/pages/services/creative/index.vue?macro=true";
import { default as ecommerce7rFD7KU4kWMeta } from "/home/michael/Desktop/michael-strain/pages/services/ecommerce.vue?macro=true";
import { default as indexdB7ZMhnzHbMeta } from "/home/michael/Desktop/michael-strain/pages/services/index.vue?macro=true";
import { default as flatbedHomesvv131jfLJBMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/flatbedHomes.vue?macro=true";
import { default as handymanNW2b2etkPZMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/handyman.vue?macro=true";
import { default as homeCareXjvlDHZWgqMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/homeCare.vue?macro=true";
import { default as indexnYiwhj38oKMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/index.vue?macro=true";
import { default as landscapinggiG6R7PYuDMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/landscaping.vue?macro=true";
import { default as lexingtonD8UwzSmDHwMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/lexington.vue?macro=true";
import { default as managementServicesZKvkFk5GvAMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/managementServices.vue?macro=true";
import { default as panamaWorkforceHousingdLMcQtTRCOMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/panamaWorkforceHousing.vue?macro=true";
import { default as pineKeycW8S9vnbThMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/pineKey.vue?macro=true";
import { default as sustainableHousing7flV8NfgoZMeta } from "/home/michael/Desktop/michael-strain/pages/services/realEstate/sustainableHousing.vue?macro=true";
import { default as socialMediaMarketingtoqTuSCnAZMeta } from "/home/michael/Desktop/michael-strain/pages/services/socialMediaMarketing.vue?macro=true";
import { default as webDevelopment5bk4HcWIrGMeta } from "/home/michael/Desktop/michael-strain/pages/services/webDevelopment.vue?macro=true";
import { default as sponsor2ve0AahkDXMeta } from "/home/michael/Desktop/michael-strain/pages/sponsor.vue?macro=true";
import { default as web_45designWOwQ8gtqauMeta } from "/home/michael/Desktop/michael-strain/pages/web-design.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    component: () => import("/home/michael/Desktop/michael-strain/pages/account.vue")
  },
  {
    name: "admin-auctions",
    path: "/admin/auctions",
    component: () => import("/home/michael/Desktop/michael-strain/pages/admin/auctions/index.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/michael/Desktop/michael-strain/pages/admin/index.vue")
  },
  {
    name: "ash",
    path: "/ash",
    component: () => import("/home/michael/Desktop/michael-strain/pages/ash.vue")
  },
  {
    name: "blog-crud",
    path: "/blog/crud",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/crud.vue")
  },
  {
    name: "blog-example",
    path: "/blog/example",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/example.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/index.vue")
  },
  {
    name: "blog-laVista",
    path: "/blog/laVista",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/laVista.vue")
  },
  {
    name: "blog-laVista2",
    path: "/blog/laVista2",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/laVista2.vue")
  },
  {
    name: "blog-location",
    path: "/blog/location",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/location.vue")
  },
  {
    name: "blog-personal-timeline",
    path: "/blog/personal-timeline",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/personal-timeline.vue")
  },
  {
    name: "blog-slugmaybe",
    path: "/blog/slugmaybe",
    component: () => import("/home/michael/Desktop/michael-strain/pages/blog/slugmaybe.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/michael/Desktop/michael-strain/pages/contact.vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/home/michael/Desktop/michael-strain/pages/donate.vue")
  },
  {
    name: "goals",
    path: "/goals",
    component: () => import("/home/michael/Desktop/michael-strain/pages/goals/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/michael/Desktop/michael-strain/pages/index.vue")
  },
  {
    name: "invest",
    path: "/invest",
    component: () => import("/home/michael/Desktop/michael-strain/pages/invest.vue")
  },
  {
    name: "investors-application",
    path: "/investors/application",
    component: () => import("/home/michael/Desktop/michael-strain/pages/investors/application.vue")
  },
  {
    name: "investors",
    path: "/investors",
    component: () => import("/home/michael/Desktop/michael-strain/pages/investors/index.vue")
  },
  {
    name: "investors-portfolio",
    path: "/investors/portfolio",
    component: () => import("/home/michael/Desktop/michael-strain/pages/investors/portfolio.vue")
  },
  {
    name: "legal-disclosures",
    path: "/legal/disclosures",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/disclosures.vue")
  },
  {
    name: "legal-faqs",
    path: "/legal/faqs",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/faqs.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/index.vue")
  },
  {
    name: "legal-investor-terms",
    path: "/legal/investor-terms",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/investor-terms.vue")
  },
  {
    name: "legal-privacyPolicy",
    path: "/legal/privacyPolicy",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/privacyPolicy.vue")
  },
  {
    name: "legal-seller-terms",
    path: "/legal/seller-terms",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/seller-terms.vue")
  },
  {
    name: "legal-termsAndConditions",
    path: "/legal/termsAndConditions",
    component: () => import("/home/michael/Desktop/michael-strain/pages/legal/termsAndConditions.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/michael/Desktop/michael-strain/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/michael/Desktop/michael-strain/pages/logout.vue")
  },
  {
    name: "offer",
    path: "/offer",
    component: () => import("/home/michael/Desktop/michael-strain/pages/offer.vue")
  },
  {
    name: "oldindex",
    path: "/oldindex",
    component: () => import("/home/michael/Desktop/michael-strain/pages/oldindex.vue")
  },
  {
    name: "oldlanding",
    path: "/oldlanding",
    component: () => import("/home/michael/Desktop/michael-strain/pages/oldlanding.vue")
  },
  {
    name: "project-proposal",
    path: "/project-proposal",
    component: () => import("/home/michael/Desktop/michael-strain/pages/project-proposal.vue")
  },
  {
    name: "projects-cannabis",
    path: "/projects/cannabis",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/cannabis/index.vue")
  },
  {
    name: "projects-farm",
    path: "/projects/farm",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/farm/index.vue")
  },
  {
    name: "projects-harvest-valley",
    path: "/projects/harvest-valley",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/harvest-valley/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/michael/Desktop/michael-strain/pages/projects/index.vue")
  },
  {
    name: "reference-activetest",
    path: "/reference/activetest",
    component: () => import("/home/michael/Desktop/michael-strain/pages/reference/activetest.vue")
  },
  {
    name: "reference-pretty-test",
    path: "/reference/pretty-test",
    component: () => import("/home/michael/Desktop/michael-strain/pages/reference/pretty-test.vue")
  },
  {
    name: "reference-test",
    path: "/reference/test",
    component: () => import("/home/michael/Desktop/michael-strain/pages/reference/test.vue")
  },
  {
    name: "schedule-interview",
    path: "/schedule-interview",
    component: () => import("/home/michael/Desktop/michael-strain/pages/schedule-interview.vue")
  },
  {
    name: "scheduler",
    path: "/scheduler",
    component: () => import("/home/michael/Desktop/michael-strain/pages/scheduler.vue")
  },
  {
    name: "seller-application",
    path: "/seller-application",
    component: () => import("/home/michael/Desktop/michael-strain/pages/seller-application.vue")
  },
  {
    name: "services-business-accounting",
    path: "/services/business/accounting",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/business/accounting.vue")
  },
  {
    name: "services-business-consulting",
    path: "/services/business/consulting",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/business/consulting.vue")
  },
  {
    name: "services-business-digitalMarketing",
    path: "/services/business/digitalMarketing",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/business/digitalMarketing.vue")
  },
  {
    name: "services-business-fulfillment",
    path: "/services/business/fulfillment",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/business/fulfillment.vue")
  },
  {
    name: "services-business",
    path: "/services/business",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/business/index.vue")
  },
  {
    name: "services-business-legal",
    path: "/services/business/legal",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/business/legal.vue")
  },
  {
    name: "services-callCenter",
    path: "/services/callCenter",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/callCenter/index.vue")
  },
  {
    name: "services-creative-art-customProducts",
    path: "/services/creative/art/customProducts",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/art/customProducts.vue")
  },
  {
    name: "services-creative-art-digitalPrints",
    path: "/services/creative/art/digitalPrints",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/art/digitalPrints.vue")
  },
  {
    name: "services-creative-art-graphicDesign",
    path: "/services/creative/art/graphicDesign",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/art/graphicDesign.vue")
  },
  {
    name: "services-creative-art",
    path: "/services/creative/art",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/art/index.vue")
  },
  {
    name: "services-creative-art-physicalPaintings",
    path: "/services/creative/art/physicalPaintings",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/art/physicalPaintings.vue")
  },
  {
    name: "services-creative-authorship",
    path: "/services/creative/authorship",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/authorship.vue")
  },
  {
    name: "services-creative",
    path: "/services/creative",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/creative/index.vue")
  },
  {
    name: "services-ecommerce",
    path: "/services/ecommerce",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/ecommerce.vue")
  },
  {
    name: "services",
    path: "/services",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/index.vue")
  },
  {
    name: "services-realEstate-flatbedHomes",
    path: "/services/realEstate/flatbedHomes",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/flatbedHomes.vue")
  },
  {
    name: "services-realEstate-handyman",
    path: "/services/realEstate/handyman",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/handyman.vue")
  },
  {
    name: "services-realEstate-homeCare",
    path: "/services/realEstate/homeCare",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/homeCare.vue")
  },
  {
    name: "services-realEstate",
    path: "/services/realEstate",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/index.vue")
  },
  {
    name: "services-realEstate-landscaping",
    path: "/services/realEstate/landscaping",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/landscaping.vue")
  },
  {
    name: "services-realEstate-lexington",
    path: "/services/realEstate/lexington",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/lexington.vue")
  },
  {
    name: "services-realEstate-managementServices",
    path: "/services/realEstate/managementServices",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/managementServices.vue")
  },
  {
    name: "services-realEstate-panamaWorkforceHousing",
    path: "/services/realEstate/panamaWorkforceHousing",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/panamaWorkforceHousing.vue")
  },
  {
    name: "services-realEstate-pineKey",
    path: "/services/realEstate/pineKey",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/pineKey.vue")
  },
  {
    name: "services-realEstate-sustainableHousing",
    path: "/services/realEstate/sustainableHousing",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/realEstate/sustainableHousing.vue")
  },
  {
    name: "services-socialMediaMarketing",
    path: "/services/socialMediaMarketing",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/socialMediaMarketing.vue")
  },
  {
    name: "services-webDevelopment",
    path: "/services/webDevelopment",
    component: () => import("/home/michael/Desktop/michael-strain/pages/services/webDevelopment.vue")
  },
  {
    name: "sponsor",
    path: "/sponsor",
    component: () => import("/home/michael/Desktop/michael-strain/pages/sponsor.vue")
  },
  {
    name: "web-design",
    path: "/web-design",
    meta: web_45designWOwQ8gtqauMeta || {},
    component: () => import("/home/michael/Desktop/michael-strain/pages/web-design.vue")
  }
]